/*!
 * froala_editor v4.3.1 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2024 Froala Labs
 */

.fr-clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.fr-hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

textarea.fr-code {
  display: none;
  width: 100%;
  resize: none;
  -moz-resize: none;
  -webkit-resize: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  padding: 10px;
  margin: 0;
  font-family: "Courier New",monospace;
  font-size: 14px;
  background: #FFF;
  color: #000;
  outline: none; }

.fr-box.fr-rtl textarea.fr-code {
  direction: rtl; }

.fr-box .CodeMirror {
  display: none; }

.fr-box.fr-code-view textarea.fr-code {
  display: block; }
.fr-box.fr-code-view .fr-element, .fr-box.fr-code-view .fr-placeholder, .fr-box.fr-code-view .fr-iframe {
  display: none; }
.fr-box.fr-code-view .CodeMirror {
  display: block; }

.fr-box.fr-inline.fr-code-view .fr-command.fr-btn.html-switch {
  display: block; }
.fr-box.fr-inline .fr-command.fr-btn.html-switch {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  background: #FFF;
  color: #333333;
  -moz-outline: 0;
  outline: 0;
  border: 0;
  line-height: 1;
  cursor: pointer;
  text-align: left;
  padding: 8px 7px;
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  z-index: 2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }
  .fr-box.fr-inline .fr-command.fr-btn.html-switch i {
    font-size: 24px;
    width: 24px;
    text-align: center; }
  .fr-box.fr-inline .fr-command.fr-btn.html-switch.fr-desktop:hover {
    background: #ebebeb; }
